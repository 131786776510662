<template>
  <div class="page">
    <van-nav-bar
      fixed
      title="技术参数"
      left-arrow
      @click-left="navBack"
    ></van-nav-bar>
    <div class="container">
      <van-row class="table-header">
        <van-col span="6">参数名称</van-col>
        <van-col span="6">数据类型</van-col>
        <van-col span="6">参数值</van-col>
        <van-col class="unit" span="6">参数单位</van-col>
      </van-row>
      <van-row v-for="item in techParams" :key="item.id" class="table-tr">
        <van-col class="table-td" span="6"> {{ item.target }}</van-col>
        <van-col class="table-td" span="6"> {{ item.dataTypeName }}</van-col>
        <van-col class="table-td" span="6"> {{ item.basicValue }}</van-col>
        <van-col class="table-td unit" span="6"> {{ item.unitName }}</van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import { getEquipmentTechParams } from "./api";

export default {
  name: "EquipmentTechParams",
  data() {
    return {
      techParams: []
    };
  },
  created() {
    this.$nextTick(() => {
      this.getEquipmentTechParams();
    });
  },
  methods: {
    getEquipmentTechParams() {
      getEquipmentTechParams({ equipmentId: this.$route.params.id }).then(
        res => {
          this.techParams = res.list;
        }
      );
    },
    navBack() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
.page {
  height: 750px;
  overflow: hidden;

  .container {
    height: 750px;
    margin-top: 58px;

    .table-header {
      height: 16px;
      line-height: 16px;
      text-align: left;
      padding: 12px 0 12px 4px;
      border-bottom: 1px solid #eceef2;
      font-size: 14px;
      font-weight: 600;
    }

    .table-tr {
      width: 100%;
      padding: 12px 0 12px 4px;
      font-size: 14px;

      .table-td {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .unit {
      text-align: center;
    }
  }
}
</style>
